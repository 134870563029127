import {Paper, Typography } from "@material-ui/core";
import ImageHolder from '../configuration'
import {history} from '../../../App'

interface Props {
    name:string,
    description:string[],
    images?:ImageHolder[],
    link:string,
}

export default function ProjectLink(props:Props) {
    return <Paper variant="outlined" style={{
        
        margin:"10px",
        display: "inline-flex",
        verticalAlign:"middle",
    flexDirection: "column",
    justifyContent: "center",
    height:"150px",
    width:"200px",
    alignContent:"flex-start",
    alignSelf:"flex-start",
    cursor:"pointer"
    }} onClick={() => {
       history.push(props.link)
      }}>
        <Typography variant = "h5" align = "center">{props.name}</Typography>
    </Paper>
    
    
}