import { Typography} from '@material-ui/core'
import ProjectLink from './ProjectLink/ProjectLink'
import './Projects.scss'
import {ProjectLinks} from './configuration'
import React from 'react'

function Projects() {
    return <div style ={{textAlign:"center"}}>
        <Typography align = "center" variant = "h2">My Projects</Typography>
        {ProjectLinks.map((project)=> {
            return <ProjectLink name={project.name} 
            description = {project.description} link = {project.link} images = {project.images}></ProjectLink>

        })}
    </div>
}
export default Projects;