export default interface ImageHolder {
  link: string;
  alt: string;
  iframe?: boolean;
}

interface Project {
  name: string;
  description: string[];
  images?: ImageHolder[];
  link: string;
}

export const ProjectLinks: Array<Project> = [
  {
    name: "Hidden Eyes",
    description: [
      "This was my final project for my VR class. We created a game where one player would play in VR and one player would play on a computer. I worked primarily on the computer side and the networking, while my teammate worked on the VR. Here is the demo video:",
    ],
    images: [
      {
        link: "https://www.youtube.com/embed/DVJd_f_zCyA",
        alt: "Hidden Eyes",
        iframe: true,
      },
    ],
    link: "/HiddenEyes",
  },
  {
    name: "Website",
    description: [
      "I originally wanted to challenge myself to build a website from scratch - running a CentOS server with Apache running a basic HTML/CSS website. I did that, but it was slow, not responsive, and really did not highlight any of my technical skills as a programmer.",
      "So, I started again from scratch! Creating a React application on that same CentOS server, aiming to have a website that looked better, functioned better, and had better mobile support. I'm always working on it, and there is still improvements to be made, but it is a fun project to do in my free time! If you're curious what the old website looked like, I've attached an iFrame of the wayback machine.",
    ],
    images: [
      {
        link: "https://web.archive.org/web/20210531020149/https://luke-lashley.com/",
        alt: "My Website",
        iframe: true,
      },
    ],
    link: "/Website",
  },
//   {
//     name: "The Infinite Monkey Theorem",
//     description: [
//         "The Infinite Monkey Theorem states that if a monkey were given infinite amount of time to randomly hit keys on a keyboard, it would eventually type any given text. This Theorem has been used in arguments over evolution, so I created a solution to the Infinite Monkey Theorem using a basic evolutionary algorithm. This was my first time using an evolutionary algorithm in a project, so it was a great learning opportunity."
//     ],
//         link: "/InfiniteMonkey",
//   }, 
   {
       name: "Text Analyzer",
       description:[
       "Out of pure curiosity, I asked myself, how quickly do I respond to text? Who have I texted the most? How many conversations have I started with this person, vs. them with me? This application is currently just a text-based application, but I've put some thought into developping a GUI for it and mkaing it publically available. It looks at a backup of the text messages made through itunes. I also have support for android if you backup the SMS messages using an app that returns the messages in a JSON format. I created this in Java.",
    "Results look like this:",
    "Name: Example ",
        "Total Text sent= 1544",
        "Your Average Length: 39",
        "Your Emojis: 4",
        "Your Average Word Count: 7",
        "Your Total Word Count: 5976",
        "Your average Response Time: 91 seconds",
        "Converstaions you've started: 32",
        "Your Total Text: 752",
        "Your most common words: like if not i’m her don’t idk about they think ",
        "Their Average Length: 32",
        "Their Emojis: 36",
        "Thier Average Word Count: 6",
        "Their total word count: 5168",
        "Their average Response Time: 137 seconds",
        "Converstaions they've started: 28",
        "Their Total Text: 792",
        "Their Most Common words: not like u no if haha i’m he yes on"

       ],
       link:"/TextAnalyzer"
   },
   {
       name:"Escape Room",
        description:[
            "This was my first real project with Unity, so it holds a special place in my heart. It was a full escape room, with puzzles and custom made models. It was a lot of fun to make and really opened my eyes to the world of game engines."
        ],
        images:[
            {
                link: "https://www.youtube.com/embed/TooDrYmiAK8",
                alt: "Escape Room Video",
                iframe: true,
              },
        ],
       link:"/EscapeRoom"
    },
    {
        name:"NCAA Database",
         description:[
             "For my Databases class, I had to create a database final project that implements a front-end and a backend. My responsibility was primarily the backend, creating the HTTP calls for the database to work, as well as gathering the data. But I also was the one who figured out how to get angular to work with springboot. Here are some pictures of the database."
         ],
         images:[
             {
                 link: "../../../assets/images/database1.png",
                 alt: "Database picture One",
                 iframe: false,
               },
               {
                link: "../../../assets/images/database2.png",
                alt: "Database picture One",
                iframe: false,
              },
              {
                link: "../../../assets/images/database3.png",
                alt: "Database picture One",
                iframe: false,
              },
         ],
        link:"/NCAA"
     },
     {
         name:"Candy Recommender",
         description:[
            "For my midterm in my iOS class, I decided to challenge myself to create something that was not covered in the scope of the class. I created a Candy recommendation app. I learned how Apple's CoreML worked as well as a lot of other super useful Swift development tricks and data-cleaning methods."
         ],
         images:[
            {link:"https://www.youtube.com/embed/ux6s25pYzmc",
            alt:"Candy Recommender",
            iframe:true}
         ],
         link:"/CandyRecommender"
     },
     {
        name:"Gullah Geechee Marketplace",
        description:[
            "In my capstone class, we created a marketing platform for the Gullah Geechee Community. Realizing that Wordpress wouldn't fit our needs, we used something new for the NMI - a headless CMS (specifically storyblok) We did so with the additional tech stacks of React, next.js, and tailwind-css. We created a platform that when a business submits their location, it will be automatically added as a page and as a location on the map once it is approved. This allows our clients to keep their website up to date and add new locations without any programming experience."
        ],
        images:[
            {link:"https://www.youtube.com/embed/RLLKiZvI-lE",
            alt:"Our Final presentation",
            iframe:true}
        ],
        link:"/GGM"
    },
    {
        name:"NMIXperts",
        description:[
            "New Media Experts or NMIXperts was a new initiative created in response to the need for tutoring for all of the New Media Students. As a NMIXpert, I helped with any iOS related questions students had. I primarily helped an emerging media Graduate student work on their iWatch Bus tracking app.",
            " I was one of the founding 5 NMIXperts."
        ],
        link:"/NMIXperts"
    },
    // {
    //     name:"Matching Game",
    //     description:[
    //         "My sister works with high schoolers and needed a digital matching game during the pandemic for her teens to play when they met. I created this in a weekend for her and even added a timer to make it a competition. Because everything is more fun as a competition."
    //     ],
    //     images:[
    //         {link:"../MatchingGame/index.html",
    //         alt:"Matching Game iframe",
    //         iframe:true}
    //     ],
    //     link:"/Matching"
    // }
];
