import "./Header.scss";
import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Home } from "@material-ui/icons";
import { history } from "../../App";
import logo from "../../assets/images/logo.png";
import SideDrawer from "../SideDrawer/SideDrawer";

const useStyles = makeStyles({
  navbarDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
  },
  navDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `uppercase`,
    color: `white`,
  },
});

export interface Link {
  title: string;
  path: string;
  icon?: string;
}

export const navLink: Array<Link> = [
  { title: `Projects`, path: `/projects` },
  { title: `Resume`, path: `/resume` },
];

const Header = () => {
  const classes = useStyles();

  return (
    <AppBar position="static">
      <Box className="header-box">
        <img src={logo} alt="logo" width="200px" height="200px" />
      </Box>
      <Toolbar>
        <Container maxWidth="md" className={classes.navbarDisplayFlex}>
          <Hidden mdUp>
            <SideDrawer />
          </Hidden>
          <Hidden smDown>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="home"
              onClick={() => {
                history.push("/");
              }}
            >
              <Home fontSize="large" />
            </IconButton>
            <List
              component="nav"
              aria-labelledby="main navigation"
              className={classes.navDisplayFlex}
            >
              {navLink.map(({ title, path }) => (
                <ListItem
                  button
                  onClick={() => {
                    history.push(path);
                  }}
                >
                  <ListItemText className = {classes.linkText}primary={title} />
                </ListItem>
              ))}
              <ListItem button onClick={()=> {
            window.location.href = 'https://www.linkedin.com/in/luke-lashley/'; 

              }}
              ><ListItemText className = {classes.linkText} primary={"LinkedIn"}/></ListItem>
            </List>
          </Hidden>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
