import { Button, Hidden, Typography } from "@material-ui/core";
import React from "react";
import "./pb.scss";
import ReactGA from 'react-ga';

const downloadFile = () => {
    window.location.href = "https://luke-lashley.com/block.exe"
    ReactGA.event({
      category: 'Form',
      action: 'Form Submit',
      transport: 'beacon'
    });
  }


function PB() {
    
  return ( 
    <Button variant="contained" onClick = {downloadFile}> Download </Button>

  );
}
export default PB;
