import { Hidden, Typography } from "@material-ui/core";
import ImageHolder from "../configuration";
interface Props {
    name:string,
    description:string[],
    images?:ImageHolder[],
    link:string,
}

export function ProjectTemplate(props:Props) {
    return <div>
        <Typography align="center" variant = "h1">{props.name}</Typography>
        {props.description.map((desc) => {
            return <div><Typography style={{marginLeft:"10px"}}>{desc}</Typography>
            <br></br>
            </div>
        })}
        {props.images?.map((image) => {
                return <div>
                    <Hidden smDown>
                {image.iframe && <iframe style={{margin:"auto", display: "block"}} width= {"560"} height={"315"}  src={image.link}  frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title = {props.name} allowFullScreen></iframe> }
                </Hidden>
                <Hidden mdUp>
                {image.iframe && <iframe style={{margin:"auto", display: "block"}} width= {"95%"} height={"315"}  src={image.link}  frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title = {props.name} allowFullScreen></iframe> }

                </Hidden>
                {!image.iframe && 
                <img src={require(""+image.link)} alt={image.alt}/>}
                </div>
                
            })}
    </div>
}