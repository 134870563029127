import { Hidden, Typography } from "@material-ui/core";
import React from "react";
// @ts-ignore
import headshot from "../../assets/images/headshot.JPG";
import { MatchingGame } from "../Projects/MatchingGame/MatchingGame";
import "./HomePage.scss";

function HomePage() {
  return ( 
    <div style={{ display: "inline-flex", marginRight:"10px"}}>
        <Hidden mdUp>
        <div className="divs-small">
        <img className="headshot-small" src={headshot} alt="My headshot" />
        <Typography align = "center">Luke Lashley</Typography>
        <a href="mailto:lukelashley99@gmail.com"><Typography align = "center">lukelashley99@gmail.com</Typography></a>
        <Typography align = "center">Aspiring Software Developer</Typography>
        <Typography align = "center">University of Georgia Alumni</Typography>


      </div>
      <div className="divs-small2">
        <Typography variant = "h4" align="center">About me</Typography>

        <br></br>

        <Typography>
          Ever since I was a kid, I was the go-to "tech person". My parents love
          to tell the story of my aunt calling me when I was only 6 years old
          for computer advice. As I grew up, I loved playing with friends, being
          on my soccer and lacrosse team, and spending days in the backyard with
          my neighbors. Oppositely, I enjoyed spending time on my computer,
          playing internet-based games that involved strategy and creativity. I
          was a curious child and appreciated that I could use the Internet to
          learn, explore new opinions, or quickly find an answer on Google.
        </Typography>
        <br></br>

        
        <Typography>
          In my senior year of high school I decided to do "Move on When
          Ready." This program allows students to enroll in college full-time
          rather than taking regular senior year courses. I stayed at Georgia
          State for a year and a half after I graduated high school, where I got
          my core curriculum out of the way. During my sophomore year, I
          transferred to the University of Georgia where I recently graduated
          with a Bachelor of Science in Computer Science, with Certificates in
          Informatics and New Media.
        </Typography>
        <br></br>
      </div>
          </Hidden>
        <Hidden smDown>
      <div className="divs">
        <img className="headshot" src={headshot} alt="My headshot" />
        <Typography align = "center">Luke Lashley</Typography>
        <a href="mailto:lukelashley99@gmail.com"><Typography align = "center">lukelashley99@gmail.com</Typography></a>
        <Typography align = "center">Aspiring Software Developer</Typography>
        <Typography align = "center">University of Georgia Alumni</Typography>

      </div>
      <div className="divs">
        <Typography variant = "h1" align="center">About me</Typography>
        <br></br>
        <Typography>
          Ever since I was a kid, I was the go-to "tech person". My parents love
          to tell the story of my aunt calling me when I was only 6 years old
          for computer advice. As I grew up, I loved playing with friends, being
          on my soccer and lacrosse team, and spending days in the backyard with
          my neighbors. Oppositely, I enjoyed spending time on my computer,
          playing internet-based games that involved strategy and creativity. I
          was a curious child and appreciated that I could use the Internet to
          learn, explore new opinions, or quickly find an answer on Google.
        </Typography>
        <br></br>

        <Typography>
          My love for technology has always been apparent, but I particularly
          remember my interest being peaked when I walked into a computer repair
          shop near my home and saw a plethora of technology. The day I turned
          14, the legal age to work in Georgia, I got a job at that same
          computer repair shop. Unfortunately, this first job was cut short, as
          the shop closed at the end of that year. Eager to continue to grow my
          skills and understanding of technology, I started working around my
          neighborhood doing computer repair. I appreciated the experience, and
          my neighbors valued the quality computer repairs at a fraction of a
          shop's price. I ended up receiving too many jobs to handle as a full
          time high-school student. I decided to focus more on my education and
          find a job with more consistent hours. I started to work for a company
          who did corporate IT for companies around the metro-Atlanta area. We
          supported non-profits, law firms, healthcare, and various other
          companies.
        </Typography>
        <br></br>

        <Typography>
          In my senior year of high school I decided to do "Move on When
          Ready." This program allows students to enroll in college full-time
          rather than taking regular senior year courses. I stayed at Georgia
          State for a year and a half after I graduated high school, where I got
          my core curriculum out of the way. During my sophomore year, I
          transferred to the University of Georgia where I recently graduated
          with a Bachelor of Science in Computer Science, with Certificates in
          Informatics and New Media.
        </Typography>
        <br></br>

        <Typography>
          This summer I am working with Seagate Technology as a Software
          Engineering Intern! I feel extremely blessed to get the opportunity to
          work for such a world-renowned company. I will spend the summer on the
          QDATA (Quality Data Analytics, Tools, and Automation) team. I will be
          working on a variety of Software Engineer, Data Science, and Machine
          Learning projects - programming primarily in Javascript and Python.
        </Typography>
      </div>
      </Hidden>
    </div>
  );
}
export default HomePage;
