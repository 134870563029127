import "./App.scss";
import { Router, Switch, Route } from "react-router-dom";
import Header from "./Components/Header/Header";
import HomePage from "./pages/HomePage/HomePage";
import { createBrowserHistory } from "history";
import Projects from "./pages/Projects/Projects";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { ProjectLinks } from "./pages/Projects/configuration";
import { ProjectTemplate } from "./pages/Projects/ProjectTemplate/ProjectTemplate";
import React from "react";
import Resume from "./pages/Resume/Resume";
// import { GroupMeAnalyzer } from "./pages/Projects/GroupMeAnalyzer/GroupMeAnalyzer";
import ReactGA from "react-ga";
import { MatchingGame } from "./pages/Projects/MatchingGame/MatchingGame";
import PB from "./pages/pb/pb";
export const history = createBrowserHistory();
const TRACKING_ID = "UA-200325909-2"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);


const THEME = createMuiTheme({
  typography: {
    fontFamily: ["Poppins", "Roboto"].join(","),
  },
});

function App() {
  return (
    <MuiThemeProvider theme={THEME}>
      <Router history={history}>
        <div>
          <Header></Header>
        </div>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>

          <Route exact path="/Resume">
            <Resume></Resume>
          </Route>
          {/* <Route exact path="/GroupMe">
            <GroupMeAnalyzer />
          </Route> */}
          <Route path="/projects">
            <Projects />
          </Route>


          {ProjectLinks.map((project) => {
            return (
              <Route exact path={project.link}>
                <ProjectTemplate
                  key = {project.name}
                  name={project.name}
                  description={project.description}
                  link={project.link}
                  images={project.images}
                />
              </Route>
            );
          })}

          <Route
            path="/linkedin"
            component={() => {
              window.location.href = "https://www.linkedin.com/in/luke-lashley/";
              return null;
            }}
          />
          <Route
            path="/salt"
            component={() => {
              window.location.href = "./MatchingGame.html";
              return null;
            }}
          />
              <Route exact path="/pb">
                <PB></PB>
              </Route>
            </Switch>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname)
}
);

