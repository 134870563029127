import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { Close, Home} from "@material-ui/icons";
import * as React from "react";
import MenuIcon from '@material-ui/icons/Menu';
import {history} from '../../App'
import { navLink } from "../Header/Header";
import './SideDrawer.scss'


// const useStyles = makeStyles({
//   list: {
//     width: 250
//   },
//   linkText: {
//     textDecoration: `none`,
//     textTransform: `uppercase`,
//     color: `black`
//   }
// });


function SideDrawer () {
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
      };
    
      const handleDrawerClose = () => {
        setOpen(false);
      };

      return <React.Fragment>
          <IconButton 
          onClick={handleDrawerOpen}>
              <MenuIcon  fontSize="large"></MenuIcon>
          </IconButton>
          <Drawer open={open}>
              <List>
              <IconButton edge="start" color="inherit" aria-label="home" onClick={()=>{history.push("/")}}>
            <Home fontSize="large" />
          </IconButton>
          <IconButton onClick={handleDrawerClose}>
              <Close fontSize="large"/>
          </IconButton>
              {navLink.map(({ title, path }) => (
                  <ListItem key = {title} button onClick={()=>{history.push(path);
                  handleDrawerClose()}} >
                    <ListItemText primary={title} />
                  </ListItem>
              ))}
                            <ListItem button onClick={()=> {
            window.location.href = 'https://www.linkedin.com/in/luke-lashley/'; 

              }}
              ><ListItemText  primary={"LinkedIn"}/></ListItem>

              </List>
          </Drawer>
      </React.Fragment>

}

export default SideDrawer;
